// (Copyright) Confluent, Inc.

// @flow
import React from 'react';
import styled from 'styled-components';

const TooltipContent = styled.div`
  max-width: 320px;
`;
const StyledHeader = styled.div`
  font-weight: 600;
`;

export const pluginTypeTooltip = (
  <TooltipContent>
    <StyledHeader>Sink connector</StyledHeader>
    Sink connectors consume messages from Kafka topics and export them to external systems (eg,
    Amazon S3 or MySQL).
    <br />
    <br />
    <StyledHeader>Source connector</StyledHeader>
    Source connectors consume data from external systems (eg, Amazon S3 or MySQL) and write them to
    Kafka topics.
    <br />
    <br />
    <StyledHeader>Transforms</StyledHeader>
    Single Message Transformations (SMTs) are applied to messages as they flow through Connect. SMTs
    transform inbound messages after a source connector has produced them, but before they are
    written to Kafka. Or, SMTs transform outbound messages before they are sent to a sink connector.
  </TooltipContent>
);

export const enterpriseSupportTooltip = (
  <TooltipContent>
    <StyledHeader>Confluent supported</StyledHeader>
    Confluent enterprise support is included as part of a Confluent subscription. Contact us
    (confluent.io/contact) to learn more.
    <br />
    <br />
    <StyledHeader>Partner supported</StyledHeader>
    Enterprise support provided by the partner who maintains the plugin.
    <br />
    <br />
    <StyledHeader>None</StyledHeader>
    No enterprise support available. Join the Confluent Community Slack
    (launchpass.com/confluentcommunity) for help from other members.
  </TooltipContent>
);

export const verificationTooltip = (
  <TooltipContent>
    <StyledHeader>Confluent built</StyledHeader>
    Built, tested, and supported by Confluent.
    <br />
    <br />
    <StyledHeader>Confluent tested</StyledHeader>
    Community plugin that is tested and supported by Confluent.
    <br />
    <br />
    <StyledHeader>Verified gold</StyledHeader>
    Fully supported partner integrations that meet the highest level of functional compatibility
    with the Confluent Platform ecosystem. For full details, see
    confluent.io/confluent-verified-integration-faqs
    <br />
    <br />
    <StyledHeader>Verified standard</StyledHeader>
    Fully supported partner integrations that meet a standard level of functional compatibility with
    the Confluent Platform ecosystem. For full details, see
    confluent.io/confluent-verified-integration-faqs
    <br />
    <br />
    <StyledHeader>None</StyledHeader>
    Community contributions that have not been verified or tested by Confluent. Join the Confluent
    Community Slack (launchpass.com/confluentcommunity) for help from other members.
  </TooltipContent>
);

export const licenseTooltip = (
  <TooltipContent>
    <StyledHeader>Premium</StyledHeader>
    Requires purchase of Confluent Platform subscription, including a license to our Premium
    Connector(s). Our Premium Connectors can also be used free on single broker clusters (Developer
    License) or evaluated free for 30 days on an unlimited number of brokers (Evaluation License).
    <br />
    <br />
    <StyledHeader>Commercial (Standard)</StyledHeader>
    Requires purchase of Confluent Platform subscription, including a license to our Commercial
    Connectors. Our Commercial Connectors can also be used free on single broker clusters (Developer
    License) or evaluated free for 30 days on an unlimited number of brokers (Evaluation License).
    <br />
    <br />
    <StyledHeader>Free</StyledHeader>
    Available for free through an Open Source or Confluent Community License.
  </TooltipContent>
);

export const cloudTooltip = 'Select plugins are available fully managed on Confluent Cloud.';
