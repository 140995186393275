// @flow
// (Copyright) Confluent, Inc.

import {
  componentLabels,
  enterpriseSupportLabels,
  verificationLabels,
  licenseLabels,
} from '../constants/labels';
import type { Plugin } from '../types';
import { getPluginCloudMetadata } from '../utils/plugin';

export const getComponentTypes = (plugin: Plugin) =>
  plugin.component_types.map<string>((componentType) => componentLabels[componentType]).join(', ');

export const getEnterpriseSupport = (plugin: Plugin) =>
  enterpriseSupportLabels[plugin.enterprise_support];

export const getVerificationLabel = (plugin: Plugin) => verificationLabels[plugin.verification];

export const getLicenseType = (plugin: Plugin) => licenseLabels[plugin.license_type];

export const getInstallationInfo = (plugin: Plugin) => {
  if (plugin.archive) {
    return ['Confluent Hub CLI', 'Download'].join(', ');
  }

  return 'Through Partner';
};

export const getDownloadUrl = (plugin: Plugin) => (plugin.archive ? plugin.archive.url : false);

const MAX_DESCRIPTION = 240;

export const shortDescription = (plugin: Plugin) => {
  const pluginCloudMetadata = getPluginCloudMetadata(plugin);
  const pluginDeprecationText = pluginCloudMetadata?.deprecation?.deprecationMessage;
  let pluginDescription = plugin.description;
  if (pluginDeprecationText) {
    if (!pluginDescription.endsWith('.')) {
      pluginDescription = pluginDescription.concat('.');
    }
    pluginDescription += ` ${pluginDeprecationText}`;
  }

  const lines = pluginDescription
    .split('. ')
    .map((line, index, all) => (index > all.length ? `${line}.` : line))
    .reduce((aggr, line) => [...aggr, ...line.split('\n\n')], []);

  const oneSentence = lines[0];
  const twoSentences = lines.slice(0, 2).join();

  if (twoSentences.length < MAX_DESCRIPTION) {
    return twoSentences;
  } else if (oneSentence.length < MAX_DESCRIPTION) {
    return oneSentence;
  }

  return `${plugin.description.replace(/<[^>]+>/, '').substring(0, MAX_DESCRIPTION)} ...`;
};

export const INITIAL_FILTERS = {
  component_types: [],
  enterprise_support: [],
  verification: [],
  license_type: [],
  cloud_availability: [],
};

type FilterGroups = $Keys<typeof INITIAL_FILTERS>;

export type Filters = { [key: FilterGroups]: Array<string> };

export const matchQuery = (plugin: Plugin, query: string) => {
  const queryLowerCase = query.toLowerCase();
  return (
    plugin.title?.toLowerCase().includes(queryLowerCase) ||
    plugin.description?.toLowerCase().includes(queryLowerCase)
  );
};

const matchFilter = (prop, values) => {
  if (!values.length) {
    return true;
  }

  if (Array.isArray(prop)) {
    for (const eachProp of prop) {
      if (values.includes(eachProp)) {
        return true;
      }
    }

    return false;
  }

  return values.includes(prop);
};

const matchFilters = (plugin: Plugin, filters: Filters) => {
  for (const filter of Object.keys(filters)) {
    if (!matchFilter(plugin[filter], filters[filter])) {
      return false;
    }
  }

  return true;
};

export const filterPlugins = (
  plugins: Array<Plugin>,
  _query: string,
  filters: Filters
): Array<Plugin> => {
  const query = _query.toLowerCase();

  return plugins.filter((plugin) => {
    if (!matchQuery(plugin, query)) {
      return false;
    }

    return matchFilters(plugin, filters);
  });
};
